import React from 'react'
import css from './Testimonials.module.css';
import Hero from '../../assets/testimonialHero.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import  {TestimonialsData} from '../../data/testimonials';


import 'swiper/css';

const Testimonials = () => {
  return (
    <div className={css.testimonials}>
        <div className={css.wrapper}>
            <div className={css.container}>
                <span>TOP RATED</span>
                <span>SEEDILY SAY HAS SUITABLE DISPOSAL AND BOY. EXERCISE JOY MAN CHILDREN REJOICED.</span>
            </div>
       
            <img src={Hero} alt="hero-image" />
            <div className={css.container}>
                <span>100k</span>
                <span>HAPPY CUSTOMERS WITH US</span>
            </div>
        </div>
        <div className={css.reviews}>Reviews</div>
        <div className={css.carousal}>
          <Swiper
            slidesPerView={3}
            slidesPerGroup={1}
            spaceBetween={30}
            breakpoints={{
              856:{
                slidesPerView:3
              },
              640:{
                slidesPerView:2
              },
              0:{
                slidesPerView:1
              }
            }}
            className={css.tCarousal}
          >
              {
                TestimonialsData.map((testimonial,i)=>(
                  <SwiperSlide key={i}>
                    <div className={css.testimonial}>
                      <img src={testimonial.image} alt={testimonial.name} />
                      <span>{testimonial.comment}</span>
                      <hr/>
                      <span>{testimonial.name}</span>
                    </div>
                  </SwiperSlide>
                ))
              }
          </Swiper>
        </div>
    </div>
  )
}

export default Testimonials