import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation,Pagination} from 'swiper';
import {SliderProducts} from '../../data/products'
import './slider.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const Slider = () => {
  
  return (
        <div className="s-container">
              <Swiper
              modules={[Navigation,Pagination]}
              loopFillGroupWithBlank={true}
              navigation={true}
              className='mySwiper'
        spaceBetween={40}
        slidesPerGroup={1}
        slidesPerView={3}
        loop={true}
        breakpoints={{
            640:{
                slidesPerView:3
            },
            0:{
                slidesPerView:1
            }
        }}
    >
      
            {
                SliderProducts.map((slide,i)=>(
                    <SwiperSlide  key={i}>
                        <div className="left-s">
                            <div className="name">
                                <span>{slide.name}</span>
                                <span>{slide.detail}</span>
                            </div>
                            <span>{slide.price}$</span>
                            <div>Shop Now</div>
                        </div>
                        <img src={slide.img} alt={slide.name} className='img-p' />
                    
                    </SwiperSlide>
                ))
            }
       
     
    </Swiper>
        </div>
  )
}

export default Slider