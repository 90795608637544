import React,{useState,useRef} from 'react'
import css from './Products.module.css';
import Plane from '../../assets/plane.png';
import {ProductsData } from '../../data/products'
import {useAutoAnimate} from '@formkit/auto-animate/react'
const Products = () => {
    const [menuProducts,setMenuproducts]=useState(ProductsData);
    const [parent]=useAutoAnimate()

    const filter = (type) =>{
        setMenuproducts(ProductsData.filter((product)=> product.type === type))
        console.log(type)
    }
  return (
    <div className={css.container} >
        <img src={Plane} alt="plane-image" />
        <h1>Our Feature Products</h1>

        <div className={css.products}>
            <ul className={css.menu}>
               
                    <li onClick={()=>setMenuproducts(ProductsData)}>All</li>
                    <li onClick={()=>filter("skin care")}>Skin Care</li>
                    <li onClick={()=>filter("conditioner")}>Conditioner</li>
                    <li onClick={()=>filter("foundation")}>Foundation</li>
            </ul>
   
            <div className={css.list} ref={parent}>
                {menuProducts.map((product,i)=>(
                    <div className={css.product} key={i}>
                        <div className="left_s">
                            <div className="name">
                                <span>{product.name}</span>
                                <span>{product.detail}</span>
                            </div>
                            <span>{product.price}$</span>
                            <div>Shop Now</div>
                        </div>
                        <img src={product.img} alt={product.name} className="img-p" />
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Products