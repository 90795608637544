import React from 'react'
import css from './Footer.module.css';
import Logo from '../../assets/logo.png';
import{ LocationMarkerIcon,PhoneIcon,InboxIcon,LoginIcon,LinkIcon,UsersIcon,ShareIcon} from '@heroicons/react/outline'
const Footer = () => {
  return (
    <div className={css.cFooterWrapper}>
        <hr/>
        <div className={css.cFooter}>
            <div className={css.cLogo}>
                <img src={Logo} alt="logo" />
                <span>amazon</span>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Contact Us</span>
                    <span className={css.pngLine}>
                        <LocationMarkerIcon className={css.icon}/>
                        <span>111 north avenue Orlando, FL 32801</span>
                    </span>
                    <span className={css.pngLine}>
                        {" "}
                        <PhoneIcon className={css.icon}/>
                        <span>354-456-3345</span>
                    </span>
                    <span className={css.pngLine}>
                        <InboxIcon className={css.icon}/>
                        <span>support@amazon.com</span>
                    </span>
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Account</span>
                    <span className={css.pngLine}>
                        <LoginIcon className={css.icon}/>
                        <span>Sign In</span>
                    </span>
                  
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Company</span>
                    <span className={css.pngLine}>
                        <UsersIcon className={css.icon}/>
                        <span>About us</span>
                    </span>
                    <span className={css.pngLine}>
                        {" "}
                        <ShareIcon className={css.icon}/>
                        <span>Share Profile</span>
                    </span>
                    
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Resources</span>
                    <span className={css.pngLine}>
                        <LinkIcon className={css.icon}/>
                        <span>Safety Privacy & Terms</span>
                    </span>
                </div>
            </div>
           
        </div>
        <div className={css.copyright}>
            <span>Copyright ©2022 by Amazon, Inc.</span>
                <span>All rights reserved.</span>
        </div>
    </div>
  )
}

export default Footer