import React from 'react'
import css from './Hero.module.css'
import Heroimg from '../../assets/hero.png'

import {RiShoppingBagFill} from 'react-icons/ri'
import {BsArrowRight} from 'react-icons/bs'
import { motion, spring} from 'framer-motion'
const Hero = () => {
    const transition={duration:3,type:"spring"}
  return (
    <div className={css.container}>
        {/* left side */}
        <div className={css.h_side}>
            <span className={css.text1}> Skin protection Cream</span>
            <div className={css.text2}>
                <span>Trendy Collection</span>
                <span>Seedily say has suitable disposal and boy. Exercise joy man children rejoiced.</span>
            </div>
        </div>
        {/* middle side */}
        <div className={css.wrapper}>
            <motion.div
                initial={{bottom:'2rem'}}
                whileInView={{ bottom:'0'}}
                transition={transition}
            className={css.blueCircle}>

            </motion.div>
            <motion.img
            transition={transition}
            initial={{bottom:'-2rem'}}
            whileInView={{ bottom:'0'}}
            src={Heroimg} alt="hero" />
            <motion.div
            initial={{right:"4%"}}
            whileInView={{right:"2%"}}
            transition={transition}
            className={css.cart2}>
                <RiShoppingBagFill/>
            
                <div className={css.signup}>
                     <span>Best Signup Offers</span>
                    <div>
                        <BsArrowRight/>
                    </div>
                </div>
            </motion.div>
        </div>
        {/* right side */}
        <div className={css.h_side}>
            <div className={css.traffic}>
                <span>1.5m</span>
                <span>Monthly Traffic</span>
            </div>
            <div className={css.customers}>
                <span>100K</span>
                <span>Happy Customers</span>
            </div>
        </div>
    </div>
  )
}

export default Hero